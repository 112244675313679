import { Flex } from "antd";
import { CSSProperties } from "react";

type ContainerProps = {
  children: React.ReactNode;
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    padding: 24,
    textTransform: "uppercase",
    flex: 1,
    textAlign: "center",
  },
  innerContainer: {
    maxWidth: 600,
    margin: "auto",
  }
}

export default function Container(props: ContainerProps) {
  return (
    <Flex align="center" vertical style={styles.container}>
      <Flex style={styles.innerContainer} vertical gap={16}>
      { props.children }
      </Flex>
    </Flex>
  )
}