const styles = {
  icon: {
    width: "16px",
    height: "16px",
    borderRadius: "8px",
    backgroundColor: "#000",
    color: "rgb(246, 221, 153)",
    fill: "currentcolor",
    padding: "4px"
  },
};

export default function EditIcon() {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="ModeEditOutlineIcon"
      style={styles.icon}
    >
        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 5.63l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41"></path>
    </svg>
  )
}