import { ConfigProvider } from "antd";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./api/store";
import "./App.css";
import "./fonts.css";
import FormPage from "./pages/FormPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainPage from "./pages/MainPage";

const router = createBrowserRouter([
  {
    path: "*",
    element: <MainPage />
  },
  {
    path: "/:redemptionCode/:simIdx",
    element: <FormPage />,
  },
]);

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 16,
          fontFamily: "Diazo Cond",
          colorPrimary: "#000000",
          colorBgContainer: "rgb(246, 221, 153)",
        },
        components: {
          Button: {
            contentFontSizeLG: 32,
            paddingBlockLG: 32,
            fontFamily: "Diazo Ex Cond Bold"
          },
          Input: {
            addonBg: "rgb(246,221,153)",
            colorTextPlaceholder: "rgb(245, 74, 44)",
            fontFamily: "Diazo Ex Cond Bold"
          },
          DatePicker: {
            fontFamily: "Diazo Ex Cond Bold",
            colorTextPlaceholder: "rgb(245, 74, 44)",
          }
        }
      }}
    >
      <ReduxProvider store={store}>
        <RouterProvider router={router} />
      </ReduxProvider>
    </ConfigProvider>
  );
}

export default App;
