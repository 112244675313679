import GetFieldsResponse from "@/schemas/GetFieldsResponse";
import GetRedemptionDataRequest from "@/schemas/GetRedemptionDataRequest";
import GetRedemptionDataResponse from "@/schemas/GetRedemptionDataResponse";
import RedeemRequest from "@/schemas/RedeemRequest";
import env from "../utils/env";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = env("REACT_APP_REDEMPTION_API_URL");

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getFields: builder.query<GetFieldsResponse, string>({
      query: (vendorId) => ({
        url: `fields?vendor_id=${vendorId}`,
        method: "GET",
      }),
    }),
    getRedemptionData: builder.query<
      GetRedemptionDataResponse,
      GetRedemptionDataRequest
    >({
      query: ({ orderNo, simIdx }) => ({
        url: `redeem?order_id=${orderNo}&sim_idx=${simIdx}`,
        method: "GET",
      }),
    }),
    postRedemption: builder.mutation<void, RedeemRequest>({
      query: ({ orderNo, simIdx, fields }) => ({
        url: `redeem?order_id=${orderNo}&sim_idx=${simIdx}`,
        method: "POST",
        body: { fields },
      }),
    }),
  }),
});

export const {
  useGetFieldsQuery,
  useLazyGetFieldsQuery,
  useGetRedemptionDataQuery,
  usePostRedemptionMutation,
} = apiSlice;
