import { Button } from "antd";

type ConfirmButtonProps = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  htmlType?: "submit"
}

export default function ConfirmButton(props: ConfirmButtonProps) {
  return (
    <Button
      type="primary"
      onClick={props.onClick}
      block
      size="large"
      htmlType={props.htmlType}
    >
      Confirm
    </Button>
  )
}