// Workaround before Vercel env variables are set up
const isProduction = process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production";
const PRODUCTION_ENV: { [key: string]: string } = {
  "REACT_APP_REDEMPTION_API_URL": "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/sb-redemption/"
}

const DEVELOPMENT_ENV: { [key: string]: string } = {
  "REACT_APP_REDEMPTION_API_URL": "https://wv90e9zk2m.execute-api.ap-southeast-1.amazonaws.com/test/sb-redemption/"
}

function getHardcodedEnv(variableName: string): string | undefined {
  if (isProduction) {
    return PRODUCTION_ENV[variableName];
  } else {
    return DEVELOPMENT_ENV[variableName];
  }
}

export default function env(variableName: string): string {
  const value = process.env[variableName] ?? getHardcodedEnv(variableName);
  if (!value) {
    throw new Error(`Environment variable ${variableName} is not set`);
  }
  return value;
}