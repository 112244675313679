import { Field } from "@/schemas/GetFieldsResponse"
import Icon from "@ant-design/icons";
import { DatePicker, DatePickerProps, Input, InputRef } from "antd";
import { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import EditIcon from "./EditIcon";
import styled from "styled-components";
import dayjs from "dayjs";

type FormInputProps = Field & {
  value: string;

  // If missing, field will be read-only
  onChange?: (value: string) => void;
};


const StyledInput = styled(Input)`
  & span > input {
    border: 0;
    padding-left: 46px;
  }
  & span > .ant-input-group-addon {
    border: 0;
  }
  & :focus {
    border: 0;
    box-shadow: none;
  }
`;

const CustomInputFieldWrapper = styled.div<{ isFocused: boolean }>`
  box-shadow: ${({ isFocused }) => isFocused ? "0 0 0 2px rgba(0, 9, 33, 0.74)" : "none"};
  transition: border 0.2s, box-shadow 0.2s, background 0.2s;
  border-radius: 16px;
`;

const StyledDatePicker = styled(DatePicker)`
  cursor: pointer;
  & div > input {
    text-align: center;
    margin-left: 18px;
  }
`

const styles: { [key: string]: CSSProperties } = {
  input: {
    textAlign: "center",
  },
  dateinput: {
    width: "100%",
    border: "0"
  }
}

export default function FormInput(props: FormInputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const fieldRef = useRef<any>(null);

  useEffect(() => {
    if (props.prefilledValue && props.onChange) {
      props.onChange(props.prefilledValue);
    }
  }, [props.prefilledValue]);

  function handleUpdateDate(dateString: string) {
    if (props.onChange) {
      props.onChange(dateString);
    }
  }

  function handleIconClick() {
    fieldRef.current?.focus();
  }

  return (<>
    { (props.type === "text" || props.type === "email" || props.type === "number") &&
      <CustomInputFieldWrapper isFocused={isFocused}>
        <StyledInput
          placeholder={isFocused ? "" : props.label}
          addonAfter={props.onChange && <Icon onClick={handleIconClick} component={() => <EditIcon />} />}
          style={styles.input}
          type={props.type}
          required={props.required}
          maxLength={props.maxLength}
          readOnly={!props.onChange}
          className="custom-input-field"
          size="large"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={props.value}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
          ref={fieldRef}
        />
      </CustomInputFieldWrapper>
    }
    { props.type === "date" &&
      <StyledDatePicker
        size="large"
        className="FormDatePicker"
        style={styles.dateinput}
        required={props.required}
        placeholder={isFocused ? "" : props.label}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        suffixIcon={<Icon onClick={handleIconClick} component={() => <EditIcon />} />}
        value={props.value ? dayjs(props.value) : null}
        onChange={(date, dateString) => handleUpdateDate(dateString as string)}
        ref={fieldRef}
      />
    }
  </>)
}