import { Flex, Image, Typography } from "antd";
import { CSSProperties } from "react";

const { Title, Text } = Typography;

type SimCardInfoProps = {
  title: string;
  imageUrl: string;
  description: string;
}
const styles: { [key: string]: CSSProperties } = {
  container: {
    backgroundColor: "#fff",
    borderRadius: 16,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
  },
  title: {
    color: "rgb(255, 70, 37)",
    paddingLeft: "10%",
    paddingRight: "10%",
    fontFamily: "Diazo Ex Cond Bold",

    textAlign: "center"
  },
  image: {
    borderRadius: 16,
  }
}

export default function SimCardInfo(props: SimCardInfoProps) {

  return (
    <Flex align="center" vertical style={styles.container} gap={16}>
      <Title level={3} style={styles.title}>{props.title}</Title>
      <Image src={props.imageUrl} preview={false} style={styles.image} />
      <Text type="secondary">{props.description}</Text>
    </Flex>
  );
}