import Container from "../components/Container";
import LogoImage from "../components/LogoImage";
import { Typography } from "antd";
const { Title } = Typography;

export default function MainPage() {
  return (
    <Container>
      <LogoImage />
      <Title level={3}>Please use a valid link to redeem your eSIM</Title>
    </Container>
  )
}